
import { ref, reactive, computed } from 'vue';

import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { email, required, minLength } from '@vuelidate/validators';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';

import { Icon, Loader } from '../../../components/ui';
import { ProfileCard } from '../../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    InputNumber,
    InputText,
    Button,
    ProfileCard,
    Icon,
    Loader
  },
  setup() {
    const { companyUserStore, companyUserStoreState } = useStores();

    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();

    companyUserStore.init(route.params.userID);

    const data = computed(() => companyUserStoreState.data || ({} as any));
    const isLoading = computed(() => companyUserStoreState.isLoading);
    const isError = computed(() => companyUserStoreState.isError);
    const isSubmiting = computed(() => companyUserStoreState.isSubmiting);

    const isShow = ref(false);
    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: ''
    });

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required }
    });

    const rulesForPassword = reactive({
      old_password: { required },
      new_password: { required, minLength: minLength(6) },
      replay_new_password: { required }
    });

    const v$ = useVuelidate(rules, data);
    const v$Password = useVuelidate(rulesForPassword, password);

    const handleSubmit = (isFormValid, isPasswordValid) => {
      let body = { ...data.value };
      v$.value.$touch();
      if (isShow.value) {
        v$Password.value.$touch();
      }
      if (isFormValid || (isShow.value && isPasswordValid && password.replay_new_password !== password.new_password)) {
        return;
      }
      if (isShow.value) {
        body = { ...body, ...password, user: body.id };
      }

      companyUserStore.saveChanges(body, toast, t);
    };

    const setAvatar = (file) => {
      data.value.avatar = file;
    };

    const toggleBlock = () => {
      companyUserStore.toggleBlock();
    };

    return {
      v$,
      v$Password,
      data,
      isShow,
      password,
      handleSubmit,
      isLoading,
      isError,
      isSubmiting,
      toggleBlock,
      setAvatar
    };
  }
};
